<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Поставка</div>
                <div class="page__desc">Подтверждение доставки</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="supplyPlan !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="store_id">Магазин</label>
                <div v-if="stores" class="control-group">
                    <select
                            v-model="supplyPlan.store_id"
                            id="store_id"
                            class="form-control"
                            disabled>
                        <option
                                v-for="store in stores"
                                v-bind:key="store.id"
                                :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="product_id">Продукт</label>
                <div v-if="products" class="control-group">
                    <select
                            v-model="supplyPlan.product_id"
                            id="product_id"
                            class="form-control"
                            disabled>

                        <template
                                v-for="cc in products"
                                :label="cc['title']">

                            <optgroup
                                    v-bind:key="cc['id']"
                                    :label="cc['title']"></optgroup>

                            <optgroup
                                    v-for="c in cc['categories']"
                                    v-bind:key="c['id']"
                                    :label="'↳' + c['title']">

                                <option
                                        v-for="p in c['products']"
                                        v-bind:key="p['id']"
                                        :value="p['id']">{{ p['title'] }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <!--<div-->
                        <!--v-for="item in supplyPlan.quantity_purchased"-->
                        <!--v-bind:key="item"-->
                        <!--class="row">-->

                    <div
                            class="row">

                    <div class="col-sm-3">
                        <label for="quantity_purchased">Принято</label>
                        <div class="control-group">
                            <div class="input-group">
                                <input
                                        v-model="quantity"
                                        :max="supplyPlan.quantity_purchased"
                                        :placeholder="supplyPlan.quantity_purchased"
                                        id="quantity_purchased"
                                        type="number"
                                        class="form-control">
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-light" disabled> / </button>
                    <div class="col-sm-3">
                        <label for="quantity_ordered">Заказано</label>
                        <div class="control-group">
                            <div class="input-group">
                                <input
                                        v-model="supplyPlan.quantity_purchased"
                                        id="quantity_ordered"
                                        type="number"
                                        class="form-control"
                                        disabled>
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-sm-3">-->
                        <!--<label for="expired_at">Срок годности</label>-->
                        <!--<div class="control-group">-->
                            <!--<input-->
                                    <!--v-model="expired_at[item-1]"-->
                                    <!--id="expired_at"-->
                                    <!--type="datetime-local"-->
                                    <!--class="form-control">-->
                        <!--</div>-->
                        <!--<br>-->
                    <!--</div>-->
                    <div class="col-sm-2">
                        <label for="price">Цена закупки</label>
                        <div class="control-group">
                            <input
                                    v-model="price"
                                    id="price"
                                    type="text"
                                    placeholder="1.00"
                                    class="form-control">
                        </div>
                        <br>
                    </div>
                </div>
                <br>

                <!--<label for="file">Фактура</label><br>-->
                <!--<div class="control-group">-->
                    <!--<input ref="file" id="file" type="file" name="file" class="form-control">-->
                <!--</div>-->
                <!--<br>-->

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>

<style scoped>
    .row {
        margin-bottom: 5px;
    }
</style>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSuppliesPlanConfirm',
        data() {
            return {
                expired_at: null,
                price: null,
                quantity: 0
            }
        },
        computed: {
            supplyPlan() {
                return this.$store.state.suppliesPlan.supplyPlan
            },
            products() {
                return this.$store.state.promotions.promotionsProducts
            },
            stores() {
                return this.$store.state.stores.stores
            },
            product() {
                if (this.products != null) {
                    for (let i = 0; i < this.products.length; i++) {
                        for (let j = 0; j < this.products[i].categories.length; j++) {
                            for (let k = 0; k < this.products[i]['categories'][j]['products'].length; k++) {
                                if (this.supplyPlan.product_id === this.products[i]['categories'][j]['products'][k]['id']) {
                                    return this.products[i]['categories'][j]['products'][k];
                                }
                            }
                        }
                    }
                }
                return null;
            }
        },
        methods: {
            ...mapActions([
                'storeStores',
                'storeSuppliesPlanGet',
                'storeSuppliesPlanConfirm',
                'catalogPromotionsProducts',
            ]),
            async getStores() {
                await this.storeStores()
            },
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async getSupplyPlan() {
                await this.storeSuppliesPlanGet({
                    id: this.$route.params.id,
                })
            },
            async update() {
                let data = [];
                // for (let i=0; i<this.quantity.length; i++) {
                //     if (this.quantity[i] !== undefined && parseInt(this.quantity[i]) !== 0 &&
                //         this.expired_at[i] !== undefined && this.price[i] !== undefined)
                //     {
                        data.push({
                            quantity: parseInt(this.quantity),
                            // expired_at: this.$moment(this.expired_at[i]).unix(),
                            price: this.price
                        });
                    // }
                // }

                const formData = new FormData();
                formData.append("id", this.supplyPlan.id);
                formData.append("data", JSON.stringify(data));
                // formData.append("file", this.$refs.file.files[0]);

                await this.storeSuppliesPlanConfirm(formData)
            }
        },
        created() {
            this.getSupplyPlan();
            this.getStores();
            this.getProducts();
        },
        watch: {
            supplyPlan: function (newVal, oldVal) {
                if (oldVal == null) {
                    this.quantity = newVal.quantity_purchased;
                }
            }
        }
    }
</script>